<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import * as yup from 'yup';

import webAppCouponApi from '@/api/webAppCoupon';
import type { WebAppCoupon } from '@/types/web-app-coupon';
import type { WebAppCouponForm } from '@/types/web-app-coupon-form';

const { t } = useI18n({});

interface Props {
  webAppCoupon: WebAppCoupon;
  open: boolean;
  hasLoyalty: boolean;
}

interface Emits {
  (event: 'close'): void;
  (event: 'save') : void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const webAppCouponData = reactive<WebAppCouponForm>({
  pointCost: props.webAppCoupon.pointCost,
});

const webAppCouponSchema = yup.object().shape({
  type: yup.string().required().label(t('functionalities.coupons.type')),
  pointCost: yup.number().when(
    'type',
    {
      is: 'redeemable',
      then: (schema) => schema.moreThan(0),
      otherwise: (schema) => schema.min(0),
    },
  ).label(t('functionalities.coupons.pointCost')),
});

const webAppCouponType = ref(props.webAppCoupon.pointCost > 0 ? 'redeemable' : 'included');
const isRedeemable = computed(() => webAppCouponType.value === 'redeemable');

const { mutate: updateWebAppCoupon, isError, isLoading } = useMutation(
  () => webAppCouponApi.update(
    props.webAppCoupon.id,
    webAppCouponData,
  ),
  {
    onSuccess: () => {
      emit('save');
    },
  },
);

watch(() => props.open, (value) => {
  if (value) {
    webAppCouponData.pointCost = props.webAppCoupon.pointCost;
  }
});
</script>
<template>
  <base-modal
    :is-open="open"
    @close="emit('close')"
  >
    <template #title>
      <div class="flex w-full flex-col gap-3">
        <h3 class="text-xl font-semibold">
          {{ $t('functionalities.coupons.editCoupon') }}
        </h3>
        <p class="text-sm text-gray-600">
          {{ $t('functionalities.coupons.editCouponDescription') }}
        </p>
        <base-error
          v-if="isError"
          :message="$t('webAppServices.errors.update')"
        />
      </div>
    </template>

    <template #content="{ setInitialFocusRef }">
      <div class="mt-8 flex flex-col gap-6">
        <base-input
          :value="webAppCoupon.name"
          :label="$t('functionalities.coupons.name')"
          disabled
        />
        <base-image-input
          :label="$t('functionalities.coupons.image')"
          disabled
          :preview-name="webAppCoupon.name"
          :preview-url="webAppCoupon.imagePrimary"
          align-image="center"
        />
        <base-input
          :value="webAppCoupon.description"
          :label="$t('functionalities.coupons.description')"
          disabled
        />
        <template v-if="hasLoyalty">
          <v-form
            class="flex flex-col gap-6"
            validate-on-mount
            :validation-schema="webAppCouponSchema"
            @submit="updateWebAppCoupon"
          >
            <div class="flex flex-col gap-4">
              <div class="text-sm font-semibold text-gray-700">
                {{ $t('functionalities.coupons.type') }}
              </div>
              <div class="flex gap-4 text-sm">
                <base-radio-input
                  :ref="setInitialFocusRef"
                  v-model="webAppCouponType"
                  value="included"
                  name="type"
                  :label="$t('functionalities.coupons.included')"
                />
                <base-radio-input
                  v-model="webAppCouponType"
                  value="redeemable"
                  name="type"
                  :label="$t('functionalities.coupons.redeemable')"
                />
              </div>
            </div>
            <div class="rounded bg-gray-50 px-4 py-2 text-xs">
              {{ $t(`functionalities.coupons.explanation.${webAppCouponType}`) }}
            </div>
            <base-input
              v-if="isRedeemable"
              v-model="webAppCouponData.pointCost"
              :label="$t('functionalities.coupons.pointCost')"
              type="number"
              name="pointCost"
              theme="numeric-input"
            />
            <div class="mt-8 flex justify-end space-x-6">
              <base-button
                type="button"
                theme="secondary"
                :label="$t('actions.cancel')"
                @click="$emit('close')"
              />
              <base-button
                type="submit"
                theme="primary"
                :label="$t('actions.save')"
                :loading="isLoading"
              />
            </div>
          </v-form>
        </template>
      </div>
    </template>
  </base-modal>
</template>
